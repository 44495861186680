import { getInitials } from "@/lib/getInitials";
import { trimText } from "@/lib/trimText";
import { useSession } from "next-auth/react";

const ProfileAvatar = () => {
    const { data: session } = useSession();
    return (
        <div className='m-0 py-0 flex items-center gap-3 cursor-pointer w-fit h-fit'>
            <div className="rounded-full bg-blue-600 w-10 h-10 flex items-center justify-center cursor-pointer">
                <span className="text-sm font-medium text-white">{getInitials("Abdur Rakib Rony")}</span>
            </div>
            <div className='flex flex-col'>
                <span className="font-medium text-sm dark:text-foreground">{session?.user?.name}</span>
                <span className="text-xs text-gray-500">{trimText(session?.user?.email, 25)}</span>
            </div>
        </div>
    )
}

export default ProfileAvatar
