export function getInitials(name) {
  // Check if the name is undefined or empty
  if (!name || typeof name !== 'string') {
    return ''; // Return an empty string in case of invalid input
  }

  // Split the name into individual words
  const words = name.split(' ');

  // Initialize an empty string to store the initials
  let initials = '';

  // Iterate through the words and add the first letter of each word to the initials
  for (let i = 0; i < words.length; i++) {
    initials += words[i][0];
  }

  // Convert the initials to uppercase
  initials = initials.toUpperCase().substring(0, 2);

  return initials;
}


